import * as React from 'react';
import { TimelineLite } from 'gsap';
import { Container } from 'components/container/Container';
import { Row } from 'components/row/Row';
import { Image } from 'components/image/Image';
import { useInView } from 'react-intersection-observer';

import s from './Intro.scss';

interface IIntroProps {
  title: string;
  text: React.ReactNode;
  staff: React.ReactNode;
  children: React.ReactNode;
}

export const Intro = ({ title, text, staff, children }: IIntroProps) => {
  const refTitle = React.useRef<HTMLHeadingElement>(null);
  const refImage = React.useRef<HTMLDivElement>(null);
  const refText = React.useRef<HTMLDivElement>(null);

  const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '-10% 0%'  });

  React.useEffect(() => {
    if (inView) {
      const t = new TimelineLite();

      t.addLabel('start', 0.5);

      if (refTitle.current) {
        t.fromTo(
          refTitle.current,
          1,
          { autoAlpha: 0, y: '40px' },
          { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
          'start',
        );
      }

      if (refImage.current) {
        t.fromTo(
          refImage.current,
          1.3,
          { autoAlpha: 0, y: '40px' },
          { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
          'start+=0.1',
        );
      }

      if (refText.current) {
        t.fromTo(
          refText.current,
          1.2,
          { autoAlpha: 0, y: '40px' },
          { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
          'start+=0.3',
        );
      }
    }
  }, [inView]);

  return (
    <Container>
      <div className={s.intro} ref={ref}>
        <Row>
          <div className={s.intro__col}>
            <h1 className={s.intro__heading} ref={refTitle}>{title}</h1>
            <div className={s.intro__imageWrap} ref={refImage}>
              <Image
                className={s.intro__image}
                src={require('assets/images/about.jpg')}
              />
            </div>
          </div>

          <div className={s.intro__text} ref={refText}>
            {text}

            <p className={s.intro__staff}>
              {staff}
            </p>
          </div>
        </Row>

        <Row>
          <div className={s.intro__colLeft}>
            <Image
              className={s.intro__image}
              src={require('assets/images/staff.jpg')}
            />
          </div>

          <div className={s.intro__colRight}>
            <Image
              className={s.intro__image}
              src={require('assets/images/brew02.jpg')}
            />
          </div>

          <div className={s.intro__colMiddle}>
            <Image
              className={s.intro__image}
              src={require('assets/images/brew03.jpg')}
            />
          </div>
        </Row>
      </div>
    </Container>
  );
};
